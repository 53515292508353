<template>
    <div class="page">
        <div class="card ln ws">
            <h2>Goblin Slayer Light Novel</h2>
            <p>Goblin Slayer! (ゴブリンスレイヤー Goburin Sureiyā) is a Japanese light novel series written by Kumo Kagyu (蜗牛くも) and illustrated by Noboru Kannatuki (神奈月昇). It began serialization in February 15, 2016. Presently, twelve volumes have been published in Japanese since.</p>
            <p>A manga adaptation by Kōsuke Kurose is serialized in the Monthly Big Gangan magazine, and a prequel manga by Kento Eida runs in Young Gangan.</p>
            <h2>Plot</h2>
            <p>In a fantasy world with monsters and adventurers, there is a man on an obsessive personal mission. His name is Goblin Slayer and he has dedicated his life to the extermination of goblins after his village was destroyed by them. After years of combating the goblin menace alone, he ends up in a party with a young priestess, elven archer, dwarven shaman, and a lizardman priest. As they continue to quest together, the cold and obsessive man slowly warms up and finds himself among people he can call friends. </p>
            <a href="https://goblin-slayer.fandom.com/wiki/Goblin_Slayer!">Source</a>
        </div>
        <div class="card">
            <div class="library-shelf">
                <div v-for="book in lightnovel" v-bind:key="book.title">
                    <h3>{{ book.title }}</h3>
                    <img :src="book.image" :alt="book.title+' Book Cover'">
                    <a v-for="link in book.links" v-bind:key="link[1]" :href="link[1]" target="_blank">{{link[0]}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import books from '@/data/book-manifest.json'

export default {
    name: 'characters',
    components: {},
    data: function () {
        return {
            lightnovel: books.lightnovel
        }
    }
}
</script>
